(function () {
	'use strict';

	angular
		.module('app')
		.factory('basecampConfig', ['$sce', 'utilities', basecampConfig]);

	function basecampConfig($sce, utilities) {
		return {
			apiConnection: apiConnection,
			config: config,
			fieldMap: fieldMap,
			unusedMap: unusedMap,
			allowHTMLMap: allowHTMLMap,
			hiddenFieldMap: hiddenFieldMap,
			readOnlyFieldMap: readOnlyFieldMap,
		};

		function apiConnection() {
			var platform = utilities.getDBKPlatform();
			return {
				authIsRedirect:
					platform === 'dbkfm' ||
					platform === 'dbkfmjs' ||
					platform === 'dbkfmwd'
						? true
						: false,
				authRedirectFunction:
					platform === 'dbkfmjs' || platform === 'dbkfmwd'
						? function (authURL, callback) {
								utilities.filemakerExternalOauth(
									platform,
									authURL,
									'code=',
									callback
								);
							}
						: false,
			};
		}

		function config() {
			return {
				id: 7,
				name: 'Basecamp',
				propertyName: 'basecamp',
				removed: true, // Removed from any new accounts. Will still appear for users before this was removed from onboarding
				getApiConnection: null,
				available: true,
				editable: true,
				isPrimary: true,
				async: true,
				seperateSchedules: true,
				hasEventCache: true,
				colorType: 'rgba',
				borderColor: 'black',
				color: 'blue',
				textColor: 'white',
				iconPath: `${_CONFIG.DBK_BASEURL}images/BasecampBig.png`,
				sourceHelp: $sce.trustAsHtml(
					'There is no real "configuration" for Basecamp Calendars; all you need to do is authorize DayBack to access your Basecamp schedules. You can, however, give this source a name below.'
				),
				parentSourceHelp: $sce.trustAsHtml(
					'Please select a calendar to the left in order to change that calendar’s settings.'
				),
				eventActionsHelp: $sce.trustAsHtml(
					'<h4>Change what happens when users click on, edit, or delete an event.</h4><p>Customize your calendar by altering the editing behavior in DayBack. Create actions that fire when you drag an event or hover over one. Reference event attributes in your action by wrapping your field names in two square brackets like this: [[id]]</p><p>Learn more: <a href="https://docs.dayback.com/article/20-event-actions" target="_blank">Creating Event Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, many of which are event actions you can paste in here. Popular actions include <a href="https://dayback.com/listing/link-related-events/" target="_blank">cascading changes through linked events downstream</a>.</p>'
				),
				customActionsHelp: $sce.trustAsHtml(
					'<h4>Add custom buttons to the event’s popover drawer.</h4><p>Take action right from the calendar by adding your own buttons beside an event. Buttons can interact with other apps, navigate to details about the event, or bring up forms you design.</p><p>Learn more: <a href="https://docs.dayback.com/article/5-custom-actions" target="_blank">Creating Button Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, several of which are buttons you can paste in here. Popular buttons include <a href="https://dayback.com/listing/zoom-meetings-calendar/" target="_blank">scheduling Zoom meetings</a>.</p>'
				),
				maxSources: 1,
				showAuthButton: true,
				authButton: `${_CONFIG.DBK_BASEURL}images/sign-in-with-basecamp.png`,
				useDefaultFieldMap: true,
				customActionsEditable: false,

				settings: {
					//setting: the name of the setting, visible: wether or not the setting is user facing, defaultValue: the default value if any
					id: {
						setting: 'id',
						visible: false,
						defaultValue: new Date().getTime(),
					},
					sourceTypeID: {
						setting: 'sourceTypeID',
						visible: false,
						defaultValue: 7,
					},
					name: {
						setting: 'name',
						visible: true,
						displayValue: 'Calendar Name',
						defaultValue: 'New Basecamp Calendar',
						format: 'name',
					},
					backgroundColor: {
						setting: 'backgroundColor',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Background Color',
						defaultValue: 'rgba(244, 244, 244, 0.85)',
						format: 'colorPicker',
						helptext:
							'The status color to used for newly created events or events with no status. We recommend a little transparency here and rgba colors are supported where the last value is the transparency (i.e. in this color...<br><br> \
										 <div class="exampleCode">rgba(244, 244, 244, 0.85)</div><br> \
									...0.85 is the transparency on a scale of 0 to 1, meaning it is nearly opaque).',
					},
					readOnly: {
						setting: 'readOnly',
						visible: true,
						displayValue: 'Read-Only',
						defaultValue: false,
						helptext:
							'Prevent editing of the schedule events in Basecamp',
						format: 'yesno',
					},
					fileDateFormat: {
						setting: 'fileDateFormat',
						visible: false,
						displayValue: 'Backend date format',
						defaultValue: 'YYYY-MM-DD',
					},
					allowDayback: {
						setting: 'allowDayback',
						visible: false,
						displayValue: 'Allow defend your time',
						defaultValue: true,
						format: 'yesno',
						helptext:
							'Setting this to false will hide the "defend your time" checkbox when creating new events in this source.',
					},
					isUnavailable: {
						setting: 'isUnavailable',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Show As Unavailable',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to true will render any events on this source as unavailable time on the calendar rather than regular events. <a href="https://docs.dayback.com/article/258-availability" target="_blank">Learn more about availability.</a>',
					},
					isMeasureOnly: {
						setting: 'isMeasureOnly',
						visible: true,
						scheduleOnly: true,
						displayValue: 'For Analytics Only',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to yes will hide these events from displaying but will allow the data from the events to be used in analytics charts.',
					},
					isMapOnly: {
						setting: 'isMapOnly',
						visible: false,
						scheduleOnly: true,
						displayValue: 'For Map Only',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to yes will hide these events from the calendar but will allow the data to be used in maps.',
					},
					allowTodo: {
						setting: 'allowTodo',
						visible: true,
						displayValue: 'Include Todos',
						defaultValue: true,
						format: 'yesno',
						helptext:
							'When set to true project ToDo items that have a date will be included on the calendar.',
					},
					htmlDescription: {
						setting: 'htmlDescription',
						visible: false,
						displayValue: 'Allow HTML In Description',
						defaultValue: true,
					},
					isPrimary: {
						setting: 'isPrimary',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Default calendar for new items',
						defaultValue: false,
						format: 'primaryCalendar',
					},
					defaultResource: {
						setting: 'defaultResource',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Show As Resource',
						defaultValue: false,
						format: 'defaultResource',
					},
					defaultDrawer: {
						setting: 'defaultDrawer',
						visible: true,
						scheduleOnly: false,
						displayValue: 'Default popover drawer',
						defaultValue: '',
						format: 'select',
						options: [
							{id: '', label: ''},
							{id: 'dateStart', label: 'Start'},
							{id: 'dateEnd', label: 'End'},
							{id: 'calendar', label: 'Calendar'},
							{id: 'list', label: 'List'},
							{id: 'resource', label: 'Resource'},
							{id: 'status', label: 'Status'},
							{id: 'customFields', label: 'Custom Fields'},
							{id: 'action', label: 'Button Actions'},
						],
						helptext:
							'Optional. Choose a drawer that should be shown automatically when clicking on an event.</br>  <a href="https://docs.dayback.com/article/167-drawers">Learn more about drawers here.</a>',
					},
				},
			};
		}

		function fieldMap() {
			return {
				allDay: {
					setting: 'allDay',
					visible: false,
					displayValue: 'All Day Event',
					defaultValue: 'all_day',
					required: false,
					unused: false,
					helptext: '',
				},
				type: {
					setting: 'type',
					visible: false,
					displayValue: 'Type',
					defaultValue: 'type',
					required: false,
					unused: false,
					helptext:
						'The event type. This can be either a Todo or an Event.',
				},
				todo: {
					setting: 'todo',
					visible: false,
					displayValue: 'Todo',
					defaultValue: false,
					required: false,
					unused: false,
					helptext: 'Is this event a todo or a standard event.',
				},
				parentListID: {
					setting: 'parentListID',
					visible: false,
					displayValue: 'Parent List ID',
					defaultValue: 'parentListID',
					required: false,
					unused: false,
					helptext:
						'Basecamp stores todos in lists. This is the id of the todo list.',
				},
				parentListName: {
					setting: 'parentListName',
					visible: false,
					displayValue: 'Parent List Name',
					defaultValue: 'parentListName',
					required: false,
					unused: false,
					helptext:
						'Basecamp stores todos in lists. This is the name of the todo list.',
				},
				eventID: {
					setting: 'eventID',
					visible: false,
					displayValue: 'Event id',
					defaultValue: 'id',
					required: true,
					unused: false,
					helptext:
						'The primary key or unique ID in your table. For all the fields that follow, use <i>just</i> your field name, not the table and field name. So if your field was called "id" you\'d enter id above, not MyTable::id',
				},
				start: {
					setting: 'start',
					visible: false,
					displayValue: 'Start Date/Time',
					defaultValue: 'starts_at',
					labelValue: 'Start',
					required: true,
					unused: false,
					helptext:
						'The event start timestamp rendered as a <strong>number</strong>. This is probably a new field in your table or is the field SampleEvents::DBk_TimestampstartCalcNum if you\'re using DayBack.fmp12. <br><br> Here is how we define this calc (remember that the calc should be set to return a number): <br><br><div class="exampleCode">' +
						'Let ( [ <br><br>' +
						'// ===== Assign your date and time fields here ===== <br><br>' +
						"&nbsp; ds = DateStart ; // Your event's start date field <br>" +
						"&nbsp; ts = TimeStart  // Your event's start time field <br><br>" +
						"// ===== You shouldn't have to edit below this line ===== <br><br>" +
						']; <br><br>' +
						'GetAsNumber ( <br>' +
						'&nbsp; Timestamp ( ds ; Max ( ts ; 0 ) ) <br>' +
						'&nbsp; ) <br>' +
						')<br><br><br>' +
						'</div>',
				},
				end: {
					setting: 'end',
					visible: false,
					displayValue: 'End Date/Time',
					defaultValue: 'ends_at',
					labelValue: 'End',
					required: false,
					unused: false,
					helptext:
						'The event end timestamp rendered as a <strong>number</strong>. This is probably a new field in your table or is the field SampleEvents::DBk_TimestampEndCalcNum if you\'re using DayBack.fmp12. <br><br> Here is how we define this calc (remember that the calc should be set to return a number): <br><br><div class="exampleCode">' +
						'Let ( [ <br><br>' +
						'// ===== Assign your date and time fields here ===== <br><br>' +
						"&nbsp; ds = DateStart ; // Your event's start date field <br>" +
						"&nbsp; de = DateEnd ; // Your event's end date field <br>" +
						"&nbsp; ts = TimeStart ; // Your event's start time field <br>" +
						"&nbsp; te = TimeEnd ; // Your event's end time field <br><br>" +
						"// ===== You shouldn't have to edit below this line ===== <br><br>" +
						'&nbsp; spanmidnight = not IsEmpty ( te ) and te < ts <br><br>' +
						']; <br><br>' +
						'GetAsNumber ( <br>' +
						'Timestamp ( <br>' +
						'&nbsp; If ( spanmidnight and IsEmpty ( de ) ; ds + 1 ; Max ( ds ; de ) ) <br>' +
						'; <br>' +
						'&nbsp; If ( IsEmpty ( te ) ; Max ( ts ; 0 ) ; te ) <br>' +
						'&nbsp; ) <br>' +
						'&nbsp; ) <br>' +
						')<br><br><br>' +
						'</div>',
				},
				title: {
					setting: 'title',
					visible: false,
					displayValue: 'Event display calc',
					defaultValue: 'summary',
					hideAdvanced: true,
					required: true,
					unused: false,
					helptext:
						"This is what shows as text for your event in the calendar before you click on an event; it's is how the event renders in the month view, for example. This is usually a calculated field combining the title of the event with some information about the event's description or related contact. If you're using DayBack.fmp12 this is the field SampleEvents::DBk_EventSummaryCalc and you can add that field to your table, or copy it's definition below.<br><br>You'll see that our example also <strong>substitutes carriage returns</strong> and you will want to do the same in your version of this field. Here is an example: <br><br><div class=\"exampleCode\">" +
						'Let ( [ <br><br>' +
						'// ===== Edit the lines below to reflect the fields in your events table ===== <br><br>' +
						'&nbsp; s = Summary ; // the title of your event <br>' +
						"&nbsp; desc = Description ;  // your event's description or notes field <br>" +
						"&nbsp; cont = SampleContacts::NameFull ; // your contact's name (optional) <br>" +
						"&nbsp; ph = SampleContacts::Phone ; // your contact's phone number (optional) <br>" +
						"&nbsp; em = SampleContacts::Email ; // your contact's email (optional) <br><br>" +
						"// ===== You shouldn't have to edit below this line ===== <br><br>" +
						'&nbsp; d = Left ( desc ; 300  ) <br><br>' +
						']; <br><br>' +
						'// ===== Use the list function to concatenate different fields together ===== <br><br>' +
						'// ===== Then substitute out any carriage returns ===== <br><br>' +
						'Substitute ( List ( s ; cont ; ph ;  em ; d ) ; "¶" ; "\n" ) <br><br>' +
						')<br><br><br>' +
						'</div>',
				},
				titleEdit: {
					setting: 'titleEdit',
					visible: false,
					displayValue: 'Summary',
					defaultValue: 'summary',
					labelValue: 'Enter Title',
					required: true,
					unused: false,
					helptext:
						'This is the field shown in the event popover as the title or summary; this field should be editable (i.e. not a calc).',
				},
				description: {
					setting: 'description',
					visible: false,
					displayValue: 'Description',
					defaultValue: 'description',
					labelValue: 'Description',
					required: false,
					unused: false,
					helptext:
						'This is the field shown in the event popover as the description (a longer text field); this field should be editable (i.e. not a calc).',
				},
				resource: {
					setting: 'resource',
					visible: false,
					displayValue: 'Resource',
					defaultValue: 'participants',
					labelValue: 'Resource',
					required: false,
					unused: false,
					helptext:
						'Resources drive the Resource views in DayBack (Schedule and Grid). Resources are also standard filters to quickly focus on the Events assigned to this Resource. By default, DayBack maps to the Owner/Assigned To of the record, but any field can be mapped. Learn more about Resources here.',
				},
				status: {
					setting: 'status',
					visible: false,
					displayValue: 'Status',
					defaultValue: 'status',
					labelValue: 'Status',
					required: false,
					unused: false,
					helptext:
						'The status of the Event. Statuses can be used to further color code and filter Events in this source. See more about Statuses here.',
				},
				tags: {
					setting: 'tags',
					visible: false,
					displayValue: 'Tags',
					defaultValue: 'tags',
					labelValue: 'Tags',
					required: false,
					unused: false,
					helptext:
						'The field that holds your event "tags" --this is also the field DayBack will use to certain meta-data about your events.',
				},
				eventURL: {
					setting: 'eventURL',
					visible: false,
					displayValue: 'External Event URL',
					defaultValue: 'app_url',
					labelValue: 'Event URL',
					required: false,
					unused: false,
					helptext:
						'An external url to show the event on a different platform.',
				},
				done: {
					setting: 'done',
					visible: false,
					displayValue: 'Todo Completed',
					defaultValue: 'completed',
					required: false,
					unused: false,
					helptext: 'The completed status of the event in Basecamp',
				},
			};
		}

		function unusedMap() {
			return {
				location: true,
				contactID: true,
				contactName: true,
				projectID: true,
				projectName: true,
				customFields: true,
			};
		}

		function allowHTMLMap() {
			return {};
		}
		function hiddenFieldMap() {
			return {};
		}
		function readOnlyFieldMap() {
			return {};
		}
	}
})();
